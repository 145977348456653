
export default function debounce(callback, wait, immediate = true) {
  let timeout;
  return async function () {
    const callNow = immediate && !timeout;
    const next = async () => await callback.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(next, wait);

    if (callNow) {
      await next();
    }
  };
};
