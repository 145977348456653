
// This store is for the currently active room

import { defineStore } from "pinia";

import { doGet } from "@/classes/requests";

export const useRoomStore = defineStore("room", {
  state: () => ({
    roomId: null,
    room: {},
  }),
  actions: {
    // Load room content
    // If expanded, list items are expanded too
    async loadRoom(roomId, expanded = false) {
      if (!roomId) {
        throw new Error("Must provide roomId when loading a room");
      }
      roomId = roomId.trim();
      
      if (this.roomId !== roomId) {
        // Reset because we've switched active rooms
        this.$reset();
      }
      this.roomId = roomId;

      // Handle expanded room option
      let path = `/room/${this.roomId}`;
      if (expanded) path = path + "/expanded";

      const room = await doGet(path);
      this.room = room;

      return room;
    }
  }
});
