<template lang="pug">
  
</template>

<script>
export default {
  name: "Empty",
  title: "Empty",
  data() {
    return {

    };
  },
  created() {

  },
  components: {
    
  }
};
</script>

<style scoped lang="sass">

</style>
