
import Cookies from "js-cookie";

export default function setCookie(name, value, expires = 7) {
  Cookies.set(name, value, {
    domain: process.env.VUE_APP_COOKIE_DOMAIN,
    path: "/",
    expires,  // Days,
    sameSite: "Strict"
  });
};
