
import { createRouter, createWebHistory } from "vue-router";

import Admin from "@/views/Admin";
import AdminNamespace from "@/views/AdminNamespace";
import AppContainer from "@/views/AppContainer";
import Auth from "@/views/Auth";
import Empty from "@/views/Empty";
import Room from "@/views/Room";
import RoomSelect from "@/views/RoomSelect";
import userLoader from "./userLoader";

const routes = [{
  path: "/",
  name: "Home",
  component: Empty,
  meta: {
    isPublic: true,
  }
}, {
  path: "/auth",
  name: "Auth",
  component: Auth,
  meta: {
    isPublic: true,
    componentName: "Init"
  }
}, {
  path: "/auth/register",
  name: "Register",
  component: Auth,
  meta: {
    isPublic: true,
    componentName: "Register"
  }
}, {
  path: "/auth/blocked",
  name: "Blocked",
  component: Auth,
  meta: {
    isPublic: true,
    componentName: "Blocked"
  }
}, {
  path: "/auth/verify",
  name: "Verify",
  component: Auth,
  meta: {
    isPublic: true,
    componentName: "Verify"
  }
}, {
  path: "/app",
  name: "AppContainer",
  component: AppContainer,
  children: [{
    path: "rooms",
    name: "RoomSelect",
    component: RoomSelect
  }, {
    path: ":roomId",
    name: "Room",
    component: Room
  }, {
    path: "admin",
    name: "Admin",
    component: Admin,
    children: [{
      path: "namespace/:namespace",
      name: "AdminNamespace",
      component: AdminNamespace
    }]
  }]
}];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Middlewares
userLoader(router);

export default router;
