
import { createApp } from "vue";
import { createPinia } from "pinia";

import Cookies from "js-cookie";
import { DateTime } from "luxon";

import App from "./App.vue";
import router from "./router";
import mixinTitle from "./mixins/title";
import b64d from "@/classes/b64d";
import b64e from "@/classes/b64e";
import debounce from "@/classes/debounce";
import deleteCookie from "@/classes/deleteCookie";
import getLevenshtein from "@/classes/levenshtein";
import randomString from "@/classes/randomString";
import setCookie from "@/classes/setCookie";
import { doGet, doPost, requests } from "@/classes/requests";

// Global helper functions and constants
const mixin = {
  methods: {
    levenshtein: getLevenshtein(),
    randomString,
    debounce,
    b64e,
    b64d,
    setCookie,
    deleteCookie,
    doGet: doGet,
    doPost: doPost,
    requests,
    // https://ecommerce.shopify.com/c/ecommerce-design/t/ordinal-number-in-javascript-1st-2nd-3rd-4th-29259
    getOrdinal(n) {
      const s = ["th", "st", "nd", "rd"],
            v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    mdyDate(t) {
      // TODO Could be done with luxon DateTime
      // Creates a string MM/DD/YYYY from Date
      t = new Date(t);
      const date = ("0" + t.getDate()).slice(-2);
      const month = ("0" + (t.getMonth() + 1)).slice(-2);
      const year = t.getFullYear();

      return `${month}/${date}/${year}`;
    },
    simpleDT(date) {
      const dt = DateTime.fromJSDate(date);

      return dt.toFormat("LLL d t")
    },
    getCookie(name) {
      return Cookies.get(name);
    },
    prettyURL(str) {
      // Removes all non-alpha characters and replaces them with dashes
      // Use this to add context to URLs, useless for ids
      return str?.replace(/[^a-z0-9]/gi, "-") ?? "";
    },
    timeAgo(someDateInThePast) {
      let result = "";
      const difference = Date.now() - new Date(someDateInThePast);

      if (difference < 5 * 1000) {
        return "Just now";
      } else if (difference < 90 * 1000) {
        return "Moments ago";
      }

      // It has days
      if ((difference % 1000 * 3600 * 60 * 24) > 0) {
        if (Math.floor(difference / 1000 / 60 / 60 / 24) > 0) {
          let s = Math.floor(difference / 1000 / 60 / 60 / 24) == 1 ? "" : "s";
          return `${Math.floor(difference / 1000 / 60 / 60 / 24)} day${s}${result == "" ? "" : ","} ` + result + " ago";
        }
      }

      // It has hours
      if ((difference % 1000 * 3600 * 60) > 0) {
        if (Math.floor(difference / 1000 / 60 / 60 % 24) > 0) {
          let s = Math.floor(difference / 1000 / 60 / 60 % 24) == 1 ? "" : "s";
          return `${Math.floor(difference / 1000 / 60 / 60 % 24)} hour${s}${result == "" ? "" : ","} ` + result + " ago";
        }
      }

      // It has minutes
      if ((difference % 1000 * 3600) > 0) {
        if (Math.floor(difference / 1000 / 60 % 60) > 0) {
          let s = Math.floor(difference / 1000 / 60 % 60) == 1 ? "" : "s";
          return `${Math.floor(difference / 1000 / 60 % 60)} minute${s} ago`;
        }
      }
    }
  }
};

// Log project name
const year = new Date().getFullYear();
const padding = "color: #fff; padding: 4px; line-height: 20px;";
const redBG = `background:#F00; ${padding}`;

if (process.env.NODE_ENV === "production") {
  console.log(`%cKademy copyright 2016-${year}`,
    `background:#1B70F0; ${padding}`);
  console.log(`%cDon't paste or enter code here from strangers, they could \
steal your information, steal your account, or impersonate you!`, redBG);
} else {
  console.log("Development config loaded:");
  console.table(process.env);
}

const pinia = createPinia();

createApp(App)
  .mixin(mixin)
  .mixin(mixinTitle)
  .use(pinia)
  .use(router)
  .mount("#app");
