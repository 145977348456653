
import axios from "axios";

export const requests = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/",
  withCredentials: true,
  timeout: 15 * 1000
});;

export async function doPost(path, payload) {
  const response = await requests.post(path, payload);
  return response?.data;
};

export async function doGet(path, query) {
  const response = await requests.get(path, {
    query
  });
  return response?.data;
};
