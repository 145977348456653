<template lang="pug">

</template>

<script>

export default {
  name: "AddThing",
  data() {
    return {
      
    };
  },
  components: {

  }
};
</script>

<style scoped lang="sass">

</style>
