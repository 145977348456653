
async function getTitle(vm) {
  const { title } = vm.$options;
  if (title) {
    if (typeof title === "function")
      return await title.call(vm);
    
    return title;
  }
}

export default {
  async mounted() {
    const title = await getTitle(this);
    if (title) this.setTitle(title);
  },
  watch: {
    async "$route.fullPath"() {
      const title = await getTitle(this);
      if (title) this.setTitle(title);
    }
  },
  methods: {
    setTitle(title) {
      document.title = title;
    }
  }
};
