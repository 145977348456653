
import Cookies from "js-cookie";

export default function deleteCookie(name) {
  Cookies.remove(name, {
    domain: process.env.VUE_APP_COOKIE_DOMAIN,
    path: "/",
    sameSite: "Strict"
  });
};
