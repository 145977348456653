
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    user: {},
    authInitEmail: "",
    authNonsecret: "",
    isRegistering: "",
  })
});
