
// Loads user info before moving to first route

import { doGet } from "@/classes/requests";

import { useUserStore } from "@/stores/user";

export default function userLoader(router) {
  router.beforeEach(async (to, from, next) => {
    console.info(`${from.name} ➞ ${to.name}`);
    
    const userStore = useUserStore();

    if (!userStore.user?.id) {
      // This might be the first time we've started a new session, try loading
      try {
        const me = await doGet("/me");

        if (me?.id) {
          // User is authenticated

          userStore.user = me;

          if (me.isKademyAdmin) {
            // Load metadata for admins, not accessible to non-Kademy admins
            userStore.user.admin = await doGet("/admin");
          }

          if (to.meta?.isPublic) {
            // Cannot visit public routes
            return next({ name: "RoomSelect" });
          } else {
            return next();
          }
        } else {
          // User not signed in

          if (to.meta?.isPublic) {
            return next();
          } else {
            // Cannot visit non-public routes
            return next({ name: "Home" });
          }
        }
      } catch (e) {
        // User not signed in or token invalid
        if (to.meta?.isPublic) return next();
        return next({ name: "Home" });
      }
    } else {
      // User is logged in
      if (to.meta?.isPublic) {
        // Cannot visit public routes
        return next({ name: "RoomSelect" });
      } else {
        return next();
      }
    }
  });
};
