// Notice that these are not in the ASCII order because they are obfuscated
const chars = "LC75IiqF1sBNTzDjgyOl6nVGAJUpcSQHtfErkRmboKh3v4XdeWwYMaxZu9820P+_-";

export default function b64d(input) {
  var str = String(input).replace(/[.]+$/, ""); // #31: ExtendScript bad parse of /=
  if (str.length % 4 == 1) {
    console.error("'b64d' failed: The string to be decoded is not correctly encoded.");
    return null;
  }
  for (
    // initialize result and counters
    var bc = 0, bs, buffer, idx = 0, output = "";
    // get next character
    buffer = str.charAt(idx++);
    // character found in table? initialize bit storage and add its ascii value;
    ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
      // and if not first of each 4 characters,
      // convert the first 8 bits to one ascii character
      bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
  ) {
    // try to find character in table (0-63, not found => -1)
    buffer = chars.indexOf(buffer);
  }
  return output;
};
