// Notice that these are not in the ASCII order because they are obfuscated
const chars = "LC75IiqF1sBNTzDjgyOl6nVGAJUpcSQHtfErkRmboKh3v4XdeWwYMaxZu9820P+_-";

export default function b64e(input) {
  var str = String(input);
  for (
    // initialize result and counter
    var block, charCode, idx = 0, map = chars, output = "";
    // if the next str index does not exist:
    //   change the mapping table to "="
    //   check if d has no fractional digits
    str.charAt(idx | 0) || (map = ".", idx % 1);
    // "8 - idx % 1 * 8" generates the sequence 2, 4, 6, 8
    output += map.charAt(63 & block >> 8 - idx % 1 * 8)
  ) {
    charCode = str.charCodeAt(idx += 3 / 4);
    if (charCode > 0xFF) {
      console.error("'b64e' failed: The string to be encoded contains characters outside of the Latin1 range.");
      return null;
    }
    block = block << 8 | charCode;
  }
  return output;
};
